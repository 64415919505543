// Polyfills
import 'Utils/_closest.polyfill.js';
import 'Utils/_matches.polyfill.js';
import 'Utils/cookies.js';

// Misc
// Use log() instead of console.log()
// const log = console.log.bind(console);

// Components
import { disablePageScroll, enablePageScroll, addFillGapTarget, addFillGapSelector, setFillGapMethod } from 'scroll-lock';
//import Swiper, { Pagination, Navigation, Autoplay, FreeMode, EffectFade } from 'swiper';


document.addEventListener('DOMContentLoaded', function() {
	// obtain plugin
	var cc = initCookieConsent();

	// run plugin with your configuration
	cc.run({
		current_lang: 'fr',
		autoclear_cookies: true,                   // default: false
		page_scripts: true,                        // default: false

		// mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
		// delay: 0,                               // default: 0
		// auto_language: '',                      // default: null; could also be 'browser' or 'document'
		// autorun: true,                          // default: true
		// force_consent: false,                   // default: false
		// hide_from_bots: true,                   // default: true
		// remove_cookie_tables: false             // default: false
		// cookie_name: 'cc_cookie',               // default: 'cc_cookie'
		// cookie_expiration: 182,                 // default: 182 (days)
		// cookie_necessary_only_expiration: 182   // default: disabled
		// cookie_domain: location.hostname,       // default: current domain
		// cookie_path: '/',                       // default: root
		// cookie_same_site: 'Lax',                // default: 'Lax'
		// use_rfc_cookie: false,                  // default: false
		// revision: 0,                            // default: 0

		onFirstAction: function(user_preferences, cookie){
			// callback triggered only once on the first accept/reject action
		},

		onAccept: function (cookie) {
			// callback triggered on the first accept/reject action, and after each page load
		},

		onChange: function (cookie, changed_categories) {
			// callback triggered when user changes preferences after consent has already been given
		},

		languages: {
			'fr': {
				consent_modal: {
					description: 'Ce site internet utilise des cookies pour vous fournir la meilleure expérience de navigation.',
					primary_btn: {
						text: 'Ok',
						role: 'accept_all'              // 'accept_selected' or 'accept_all'
					},
				},
				settings_modal: {
					title: 'Cookie preferences',
					save_settings_btn: 'Save settings',
					accept_all_btn: 'Accept all',
					close_btn_label: 'Close',
					// cookie_table_caption: 'Cookie list',
					cookie_table_headers: [
						{col1: 'Name'},
						{col2: 'Domain'},
						{col3: 'Expiration'},
						{col4: 'Description'}
					],
					blocks: [
						{
							title: 'Cookie usage 📢',
							description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc-link">privacy policy</a>.'
						}, {
							title: 'Strictly necessary cookies',
							description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
							toggle: {
								value: 'necessary',
								enabled: true,
								readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
							}
						}, {
							title: 'Performance and Analytics cookies',
							description: 'These cookies allow the website to remember the choices you have made in the past',
							toggle: {
								value: 'analytics',     // your cookie category
								enabled: false,
								readonly: false
							},
							cookie_table: [             // list of all expected cookies
								{
									col1: '^_ga',       // match all cookies starting with "_ga"
									col2: 'google.com',
									col3: '2 years',
									col4: 'description ...',
									is_regex: true
								},
								{
									col1: '_gid',
									col2: 'google.com',
									col3: '1 day',
									col4: 'description ...',
								}
							]
						}, {
							title: 'Advertisement and Targeting cookies',
							description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
							toggle: {
								value: 'targeting',
								enabled: false,
								readonly: false
							}
						}, {
							title: 'More information',
							description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.',
						}
					]
				}
			}
		}
	});

	var r = document.querySelector(':root');
	var body = document.body;

	// HELP CHECK
	/*let viewportWidthEl = document.getElementById('viewport-width');

	function viewportCalc() {
		//let viewportHeight = window.innerHeight;
		let viewportWidth = window.innerWidth;
		//console.log(viewportWidth);
		//console.log(viewportHeight);

		if(window.innerWidth < 576){
			viewportWidthEl.style.backgroundColor = "red";
            viewportWidthEl.innerText = 'xs';
			//viewportHeightEl.style.backgroundColor = "red";

		} else if(window.innerWidth < 768) {
			viewportWidthEl.style.backgroundColor = "orange";
            viewportWidthEl.innerText = 'sm';

			//viewportHeightEl.style.backgroundColor = "purple";

		} else if(window.innerWidth < 992) {
			viewportWidthEl.style.backgroundColor = "green";
            viewportWidthEl.innerText = 'md';
			//viewportHeightEl.style.backgroundColor = "blue";

		} else if(window.innerWidth < 1200) {
			viewportWidthEl.style.backgroundColor = "blue";
            viewportWidthEl.innerText = 'lg';
			//viewportHeightEl.style.backgroundColor = "green";

		} else if(window.innerWidth < 1400) {
			viewportWidthEl.style.backgroundColor = "purple";
            viewportWidthEl.innerText = 'xl';
			//viewportHeightEl.style.backgroundColor = "orange";
		}

		else {
			viewportWidthEl.style.backgroundColor = "black";
            viewportWidthEl.innerText = 'xxl';
			//viewportHeightEl.style.backgroundColor = "black";
		}
	}

	viewportCalc();
	*/

	// RESIZE
	window.addEventListener("resize", function(){
		//viewportCalc();
	});

	//// HEADER TINY
	var scrollPosition = window.scrollY;
	var header = document.getElementById('header');

	window.addEventListener('scroll', function() {
		scrollPosition = window.scrollY;

		if (scrollPosition >= 60) {
			header.classList.add('--tiny');
		} else {
			header.classList.remove('--tiny');
		}
	});


	//// HEADER ON SCROLL DIRECTION
	let previousScrollPosition = 0;

	const isScrollingDown = () => {
		let goingDown = false;

		let scrollPosition = window.pageYOffset;

		if (scrollPosition > previousScrollPosition) {
			goingDown = true;
		}

		previousScrollPosition = scrollPosition;

		return goingDown;
	};

	const handleScroll = () => {
		if (isScrollingDown()) {
			header.classList.add("scroll-down");
			header.classList.remove("scroll-up");
		} else {
			header.classList.add("scroll-up");
			header.classList.remove("scroll-down");
		}
	};

	function throttle(func, wait, options) {
		var timeout, context, args, result;
		var previous = 0;
		if (!options) options = {};
		
		var later = function () {
			previous = options.leading === false ? 0 : Date.now();
			timeout = null;
			result = func.apply(context, args);
			if (!timeout) context = args = null;
		};
		
		var throttled = function () {
			var _now = Date.now();
			if (!previous && options.leading === false) previous = _now;
			var remaining = wait - (_now - previous);
			context = this;
			args = arguments;
			if (remaining <= 0 || remaining > wait) {
			if (timeout) {
				clearTimeout(timeout);
				timeout = null;
			}
			previous = _now;
			result = func.apply(context, args);
			if (!timeout) context = args = null;
			} else if (!timeout && options.trailing !== false) {
			timeout = setTimeout(later, remaining);
			}
			return result;
		};
		
		throttled.cancel = function () {
			clearTimeout(timeout);
			previous = 0;
			timeout = context = args = null;
		};
		
		return throttled;
	}

	const scrollThrottle = throttle(handleScroll, 100);

	window.addEventListener("scroll", scrollThrottle, { passive: true});

	//// BURGER BTN	
	const buttonBurger = document.getElementById('btnBurgerJS');
	var $scrollableElement = document.querySelector('.my-scrollable-element');
	
	var method = 'width'; //Available methods: none, padding, margin, width, max-width
	setFillGapMethod(method);
		
	buttonBurger.addEventListener('click', function() {
		if (body.classList.contains('burger-open')) { 
			body.classList.remove('burger-open');
            enablePageScroll($scrollableElement);
            document.querySelector('.no-js').style.overflow = "auto";
		} else {
			body.classList.add('burger-open');
			disablePageScroll($scrollableElement);
			document.querySelector('.no-js').style.overflow = "hidden";
		}
	});

		// RESIZE
		window.addEventListener("resize", function(){
		
			if (body.classList.contains('burger-open')) { 
				body.classList.remove('burger-open');
				enablePageScroll($scrollableElement);
				document.querySelector('.no-js').style.overflow = "auto";
			}
			
		});
	

	//// POPUP CONTACT	
	const buttonContact = document.getElementById('btnContactJS');
	var $scrollableElement = document.querySelector('.my-scrollable-element');
	
	var method = 'width'; //Available methods: none, padding, margin, width, max-width
	setFillGapMethod(method);
		
	buttonContact.addEventListener('click', function() {
		if (header.classList.contains('scroll-down')) { 
			header.classList.remove("scroll-down");
		}

		if (body.classList.contains('popup-open')) { 
			body.classList.remove('popup-open');
            enablePageScroll($scrollableElement);
            document.querySelector('.no-js').style.overflow = "auto";
			header.classList.add("scroll-up");
		} else {
			body.classList.add('popup-open');
			disablePageScroll($scrollableElement);
			document.querySelector('.no-js').style.overflow = "hidden";
		}
	});
	
	/*
	overlayPopup.addEventListener('click', function() {
		body.classList.remove('popup-open');
		enablePageScroll($scrollableElement);
		document.querySelector('.no-js').style.overflow = "auto";
	});
	*/

	document.onkeydown = function(evt) {
		evt = evt || window.event;
		var isEscape = false;
		if ("key" in evt) {
			isEscape = (evt.key === "Escape" || evt.key === "Esc");
		} else {
			isEscape = (evt.keyCode === 27);
		}
		if (isEscape) {
			if (body.classList.contains('popup-open')) { 
				body.classList.remove('popup-open');
				enablePageScroll($scrollableElement);
				document.querySelector('.no-js').style.overflow = "auto";
				header.classList.add("scroll-up");
			}
		}
	};

	// RESIZE
	window.addEventListener("resize", function(){
		
		if (body.classList.contains('popup-open')) { 
			body.classList.remove('popup-open');
            enablePageScroll($scrollableElement);
            document.querySelector('.no-js').style.overflow = "auto";
		}
		
	});

})


